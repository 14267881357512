<template>
  <div class="filter">
    <div class="filter-search">
      <el-input
        placeholder="请输入任务名称、企业名称、项目名称等"
        v-model="searchVal"
      >
        <el-button slot="append" class="bg-active-color" @click="onSearch">
          搜&nbsp;索
        </el-button>
      </el-input>
    </div>
    <div class="filter-type">
      <ul>
        <li>
          <div class="label">负责人：</div>
          <div>
            <el-checkbox disabled class="margin-right-3">不限</el-checkbox>
            <el-select disabled value="" placeholder="请选择">
              <el-option value="1"> </el-option>
            </el-select>
          </div>
        </li>
        <li>
          <div class="label">时间：</div>
          <div>
            <el-checkbox disabled class="margin-right-3">不限</el-checkbox>
            <el-date-picker
              disabled
              v-model="value1"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </li>
        <li v-for="item in filterType" :key="item.title">
          <div class="label">{{ item.title }}：</div>
          <el-checkbox-group
            :max="1"
            :disabled="item.disabled"
            v-model="item.checkVal"
          >
            <el-checkbox
              v-for="checkVal in item.data"
              :label="checkVal.value"
              :key="checkVal.name"
              >{{ checkVal.name }}</el-checkbox
            >
          </el-checkbox-group>
        </li>
      </ul>
    </div>
    <div class="filter-btns">
      <el-button class="btn" @click="onReset">重置</el-button>
      <el-button class="btn default-active-btn" @click="onSubmit"
        >查询</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeCity: [],
      input2: "",
      value1: "",
      options: [],
      searchVal: "",
    };
  },
  props: {
    filterType: {
      type: Array,
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.filterType[0].checkVal[0]);
    },
    onSearch() {
      // if (!this.searchVal) return;
      this.$emit("search", this.searchVal);
    },
    onReset() {
      this.$emit("reset");
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  padding: 40px 24px;
}
.filter-search {
  width: 552px;
  margin: 0 auto 20px;
  ::v-deep .el-input-group__append {
    background: #00a0e9;
    color: #fff;
    border: 1px solid #00a0e9;
    border-bottom-width: 2px;
    border-radius: 0;
    height: 39px;
  }
}
.filter-type {
  li {
    padding: 20px 0;
    border-bottom: 1px dashed #e1e6f0;
    display: flex;
    align-items: center;
    .label {
      font-size: 14px;
      color: #374567;
      width: 100px;
      margin-right: 20px;
      text-align: right;
    }
  }
  .el-select {
    ::v-deep .el-input__inner {
      width: 164px;
    }
  }
  ::v-deep .el-input__inner {
    height: 32px !important;
    width: 224px;
  }
  ::v-deep .el-range-separator {
    color: rgba(55, 69, 103, 0.45);
  }
  ::placeholder {
    font-size: 14px;
  }
  ::v-deep .el-date-editor .el-range__icon,
  ::v-deep .el-select .el-input .el-select__caret,
  ::v-deep .el-date-editor .el-range-separator {
    line-height: 24px;
  }
  .margin-right-3 {
    margin-right: 30px;
  }
  ::v-deep .el-checkbox__label {
    font-weight: 400;
  }
}

.filter-btns {
  display: flex;
  margin-top: 16px;
  .btn {
    border: 1px solid #aab0be;
    color: #aab0be;
    font-size: 14px;
    padding: 5px 16px;
    margin-right: 9px;
    border-radius: 2px;
    margin-left: 0;
    height: auto;
  }
  .default-active-btn:hover {
    background: #00a0e9;
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #374567;
}
</style>